<template>
  <body>
    <div class="container">
      <div class="card-image has-text-centered">
        <figure class="image is-3by3 is-inline-block">
          <img
            src="https://live.staticflickr.com/65535/52330006126_ef9e4c225f.jpg"
          />
        </figure>

        <div class="content">
          <h4>wei dai</h4>
          Contact me on
          <a
            id="ig-logo"
            href="https://www.instagram.com/weidvi/"
            target="_blank"
          >
            <font-awesome-icon icon="fa-brands fa-instagram" /> Instagram
          </a>
          <br />
          ..or feel free to e-mail me at <strong>wei@lzrmoos.com</strong>
        </div>
      </div>
    </div>
  </body>
</template>

<style>
body {
  margin: 0;
  overflow: hidden;
  height: 100vh;
}

.image img {
  width: auto !important;
}
</style>
